<template>
  <popup
    title="Edit question"
    @close="$emit('close')"
  >
    <template v-slot:content>
      <div class="popup-content" v-if="question">
        <!-- Title -->
        <div class="two-cols">
          <div class="field">
            <label for="question-title-en">Question title EN:</label>
            <!-- <input type="text" id="question-title-en" v-model="question.content.translations.en.title" placeholder="Question title EN"> -->
            <TextEditor 
              :value="question.content.translations.en.title" 
              @input="question.content.translations.en.title = $event"
            />
          </div>
          <div class="field">
            <label for="question-title-de">Question title DE:</label>
            <!-- <input type="text" id="question-title-de" v-model="question.content.translations.de.title" placeholder="Question title DE"> -->
            <TextEditor 
              :value="question.content.translations.de.title" 
              @input="question.content.translations.de.title = $event"
            />
          </div>
        </div>
        <!-- Subtitle -->
        <div class="two-cols">
          <div class="field">
            <label for="question-subtitle-en">Question subtitle EN:</label>
            <!-- <input type="text" id="question-subtitle-en" v-model="question.content.translations.en.subtitle" placeholder="Question subtitle EN"> -->
            <TextEditor 
              :value="question.content.translations.en.subtitle" 
              @input="question.content.translations.en.subtitle = $event"
            />
          </div>
          <div class="field">
            <label for="question-subtitle-de">Question subtitle DE:</label>
            <!-- <input type="text" id="question-subtitle-de" v-model="question.content.translations.de.subtitle" placeholder="Question subtitle DE"> -->
            <TextEditor 
              :value="question.content.translations.de.subtitle" 
              @input="question.content.translations.de.subtitle = $event"
            />
          </div>
        </div>
        <!-- Required -->
        <div class="field">
          <label class="custom-check green">
            <input type="checkbox" v-model="question.isRequired">
            <span class="checkmark"></span>
            Is Required
          </label>
        </div>
      <!-- Background -->
      <!-- <div class="field">
        <label>Background</label>
        <ImagesDropdown
          :images="quizImages.backgrounds"
          @select-image="selectBackground"
        />
        <img class="background-preview" v-if="question.content.background" :src="question.content.background" alt="">
      </div> -->
      <!-- Background side image -->
      <!-- <div class="field">
        <label>Background side image</label>
        <ImagesDropdown
          :images="quizImages.sides"
          @select-image="selectSideBackground"
        />
        <img class="background-preview" v-if="question.content.sideBack" :src="question.content.sideBack" alt="">
      </div> -->
        <!-- Status & Order -->
        <div class="two-cols">
          <!-- Status -->
          <div class="field">
            <label>Question Status:</label>
            <div class="custom-select" :class="{open: statusSelect}" @click="statusSelect = !statusSelect" v-click-away="statusAway">
              <span v-if="question.status != null">{{statuses[question.status].name}}</span>
              <span v-else>Select Status</span>
              <div class="select-dropdown" v-if="statusSelect">
                <a
                  v-for="status in statuses"
                  :key="status.id"
                  @click="question.status = status.id"
                >
                  {{status.name}}
                </a>
              </div>
            </div>
          </div>
          <!-- Order -->
          <div class="field">
            <label for="order">Order:</label>
            <input type="number" id="order" v-model="question.sort" placeholder="Order">
          </div>
        </div>
        <div class="field">
          <label>Slide From:</label>
          <div class="custom-select" :class="{open: slideFromSelect}" @click="slideFromSelect = !slideFromSelect" v-click-away="inputTypeAway">
            <span v-if="question.content.slideFrom != null">{{question.content.slideFrom}}</span>
            <span v-else>Select Slide From</span>
            <div class="select-dropdown" v-if="slideFromSelect">
              <a @click="question.content.slideFrom = 'top'">
                top
              </a>
              <a @click="question.content.slideFrom = 'right'">
                right
              </a>
              <a @click="question.content.slideFrom = 'bottom'">
                bottom
              </a>
              <a @click="question.content.slideFrom = 'left'">
                left
              </a>
            </div>
          </div>
        </div>
        <!-- Type -->
        <div class="field">
          <label>Question Type:</label>
          <div class="custom-select" :class="{open: typeSelect}" @click="typeSelect = !typeSelect" v-click-away="typeAway">
            <span v-if="question.type != null">{{types[question.type].name}}</span>
            <span v-else>Select Type</span>
            <div class="select-dropdown" v-if="typeSelect">
              <a
                v-for="type in types"
                :key="type.id"
                @click="question.type = type.id">
                {{type.name}}
              </a>
            </div>
          </div>
        </div>

        <template v-if="question.type !== null && question.type === 0">
          <div class="field">
            <label>Input Type:</label>
            <div class="custom-select" :class="{open: inputTypeSelect}" @click="inputTypeSelect = !inputTypeSelect" v-click-away="inputTypeAway">
              <span v-if="question.content.inputType != null">{{question.content.inputType}}</span>
              <span v-else>Select Input Type</span>
              <div class="select-dropdown" v-if="inputTypeSelect">
                <a @click="question.content.inputType = 'text'">
                  text
                </a>
                <a @click="question.content.inputType = 'email'">
                  email
                </a>
              </div>
            </div>
          </div>
          <div class="two-cols">
            <div class="field">
              <label for="input-placeholder-en">Input placeholder EN:</label>
              <input type="text" id="input-placeholder-en" v-model="question.content.placeholder.en.title" placeholder="Input placeholder EN">
            </div>
            <div class="field">
              <label for="input-placeholder-de">Input placeholder DE:</label>
              <input type="text" id="input-placeholder-de" v-model="question.content.placeholder.de.title" placeholder="Input placeholder DE">
            </div>
          </div>
        </template>
        <!-- Selection type -->
        <div class="field" v-if="question.type && (question.type == 1 || question.type == 2)">
          <label>Selection Type:</label>
          <div class="custom-select" :class="{open: selectionType}" @click="selectionType = !selectionType" v-click-away="selectionTypeAway">
            <span v-if="question.content.typeSelect">{{question.content.typeSelect}}</span>
            <span v-else>Select selection type</span>
            <div class="select-dropdown" v-if="selectionType">
              <a
                @click="question.content.typeSelect = 'dropdown'"
              >
                Dropdown
              </a>
              <a
                @click="question.content.typeSelect = 'list'"
              >
                List
              </a>
            </div>
          </div>
        </div>
        <div class="two-cols" v-if="question.type && question.type == 2">
          <div class="field">
            <label for="min-answers">Min answers</label>
            <input type="number" id="min-answers" v-model="question.content.minAnswers">
          </div>
          <div class="field">
            <label for="max-answers">Max answers</label>
            <input type="number" id="max-answers" v-model="question.content.maxAnswers">
          </div>
        </div>
        <!-- Single/Multi choice options -->
        <div class="options" v-if="question.type && (question.type == 1 || question.type == 2)">
          <button class="green-btn" @click="addOption()">Add option</button>
          <div
            v-for="(option, optionIndex) in question.content.options"
            :key="option.id"
            class="field option"
          >
            <div class="head">
              <label>Option {{option.id}}:</label>
              <button class="remove-btn" @click="removeOption(optionIndex)">X</button>
            </div>
            <div class="two-cols">
              <div class="field">
                <input type="text" v-model="option.translations.en.title" placeholder="Option name EN">
              </div>
              <div class="field">
                <input type="text" v-model="option.translations.de.title" placeholder="Option name DE">
              </div>
            </div>
            <div class="field">
              <input type="number" v-model="option.points" placeholder="Option points">
            </div>
            <div class="two-cols">
              <div class="field">
                <input type="text" v-model="option.translations.en.feedback" placeholder="Feedback EN">
              </div>
              <div class="field">
                <input type="text" v-model="option.translations.de.feedback" placeholder="Feedback DE">
              </div>
            </div>
            <div class="field">
              <label>Option icon</label>
              <ImagesDropdown
                class="icons-dropdown"
                :images="quizImages.icons"
                :optionIndex="optionIndex"
                @select-image="selectOptionIcon"
              />
              <img class="background-preview" v-if="option.image" :src="option.image">
            </div>
          </div>
        </div>
        <div class="three-cols" v-if="question.type && question.type == 3">
          <div class="field">
            <label for="min-value">Min value</label>
            <input type="number" id="min-value" v-model="question.content.minValue">
          </div>
          <div class="field">
            <label for="max-value">Max value</label>
            <input type="number" id="max-value" v-model="question.content.maxValue">
          </div>
          <div class="field">
            <label for="step">Step</label>
            <input type="number" id="step" v-model="question.content.steps">
          </div>
        </div>
        <!-- Slider options -->
        <div class="options" v-if="question.type && question.type == 3">
          <button class="green-btn" @click="addOption(true)">Add option</button>
          <div
            v-for="(option, optionIndex) in question.content.options"
            :key="option.id"
            class="field option"
          >
            <label>Option {{option.key}}:</label>
            <div class="field">
              <input type="number" v-model="option.key" placeholder="Option key">
            </div>
            <div class="two-cols">
              <div class="field">
                <input type="text" v-model="option.translations.en.title" placeholder="Option label EN">
              </div>
              <div class="field">
                <input type="text" v-model="option.translations.de.title" placeholder="Option label DE">
              </div>
            </div>
            <div class="field">
              <input type="number" v-model="option.points" placeholder="Option points">
            </div>
            <div class="field">
              <label>Option icon</label>
              <ImagesDropdown
                class="slider-dropdown"
                :images="quizImages.sliderIcons"
                :optionIndex="optionIndex"
                @select-image="selectOptionIcon"
              />
              <img class="background-preview" v-if="option.image" :src="option.image">
            </div>
          </div>
        </div>
        <!-- Multiple questions -->
        <div class="questions" v-if="question.type && question.type == 4">
          <button class="transitions green-btn" @click="addMultiQuestion()">Add question</button>
          <div
            v-for="(question, questionIndex) in question.content.questions"
            :key="question.id"
            class="field question">
            <label>Question {{question.id}}:</label>
            <div class="two-cols">
              <div class="field">
                <input type="text" v-model="question.translations.en.title" placeholder="Question title EN">
              </div>
              <div class="field">
                <input type="text" v-model="question.translations.de.title" placeholder="Question title DE">
              </div>
            </div>
            <label>Select a Background Color:</label>
            <div class="field">
              <div class="color-options">
                <label v-for="color in colorOptions" :key="color.value" class="color-option">
                  <input type="radio" v-model="question.background" :value="color.value" hidden/>
                  <span 
                    class="color-box" 
                    :style="{ background: color.value }"
                    :class="{ active: question.background === color.value }"
                  ></span>
                </label>
              </div>
            </div>

            <button class="transition green-btn" @click="addAnswer(questionIndex)">Add answer</button>
            <div
              v-for="answer in question.answers"
              :key="answer.id"
              class="field answer"
            >
              <label>Answer {{answer.id}}:</label>
              <div class="two-cols">
                <div class="field">
                  <input type="text" v-model="answer.translations.en.title" placeholder="Answer title EN">
                </div>
                <div class="field">
                  <input type="text" v-model="answer.translations.de.title" placeholder="Answer title DE">
                </div>
              </div>
              <div class="field">
                <input type="number" v-model="answer.points" placeholder="Answer points">
              </div>
            </div>
          </div>
        </div>
        <!-- Summary at end of quiz, or wherever the author chooses to place a summary-->
        <div class="questions" v-if="question.type && question.type == 6">
          <div class="two-cols">
            <div class="field">
              <label for="question-summary-en">Summary EN:</label>
              <TextEditor 
                :value="question.content.translations.en.summary" 
                @input="question.content.translations.en.summary = $event"
              />
            </div>
            <div class="field">
              <label for="question-summary-de">Summary DE:</label>
              <TextEditor 
                :value="question.content.translations.de.summary" 
                @input="question.content.translations.de.summary = $event"
              />
            </div>
          </div>
        </div>

        <button class="save-btn transition" @click="updateQuestion()" :disabled="buttonLoading">
          <font-awesome-icon v-if="buttonLoading" icon="spinner" spin />
          <template v-else>
            Update
          </template>
        </button>
      </div>
    </template>
  </popup>
</template>

<script>
  import quizMixin from '@/mixins/quiz'

  import Popup from '@/components/shared/Popup'
  import ImagesDropdown from '../elements/ImagesDropdown'
  import TextEditor from '../elements/TextEditor.vue'

  export default {
    name: 'EditQuestion',
    mixins: [quizMixin],
    components: {
      Popup,
      ImagesDropdown,
      TextEditor
    },
    props: {
      questionId: Number,
      quizImages: Object
    },
    data() {
      return {
        types: [
          {
            id: 0,
            name: 'Input'
          },
          {
            id: 1,
            name: 'Single Choice'
          },
          {
            id: 2,
            name: 'Multi Choice'
          },
          {
            id: 3,
            name: 'Slider'
          },
          {
            id: 4,
            name: 'Multiple questions'
          },
          {
            id : 5,
            name: 'Date'
          },
          {
            id : 6,
            name: 'Summary'
          }
        ],
        typeSelect: false,
        statuses: [
          {
            id: 0,
            name: 'Draft'
          },
          {
            id: 1,
            name: 'Active'
          }
        ],
        statusSelect: false,
        selectionType: false,
        question: null,
        activeOptionForIcon: null,
        activeQuesitonForIcon: null,
        inputTypeSelect: false,
        slideFromSelect: false,
        buttonLoading: false,
        titleEditor: null,
        colorOptions: [
          { label: 'Red', value: 'linear-gradient(180deg, rgba(255, 77, 77, 0.70) 0%, rgba(80, 66, 246, 0.31) 100%)' },
          { label: 'Orange', value: 'linear-gradient(160deg, rgba(80, 66, 246, 0.45) 0%, #AEC6CF 100%)' },
        ]
      }
    },
    methods: {
      removeOption(index) {
        this.question.content.options.splice(index, 1);

        this.question.content.options.forEach((option, i) => {
          option.id = i + 1;
        });
      },
      getQuestionHandler() {
        this.getQuestion(this.questionId)
          .then(res => {
            this.question = res.quizQuestion;
          })
      },
      updateQuestion() {
        this.buttonLoading = true;
        this.editQuestion(this.questionId, this.question)
          .then(() => {
            this.$emit('updated')
            this.buttonLoading = false;
          })
      },
      typeAway() {
        this.typeSelect = false;
      },
      statusAway() {
        this.statusSelect = false;
      },
      selectionTypeAway() {
        this.selectionType = false;
      },
      selectBackground(image) {
        this.question.content.background = image;
      },
      selectSideBackground(image) {
        this.question.content.sideBack = image;
      },
      addOption(slider) {
        let lastId = this.question.content.options.length > 0 ? this.question.content.options[this.question.content.options.length - 1].id : 0;

        let option;
        if (slider) {
          option = {
            key: null,
            points: null,
            image: null,
            translations: {
              en: {
                title: null
              },
              de: {
                title: null
              }
            }
          }
        } else {
          option = {
            id: lastId + 1,
            points: null,
            image: null,
            translations: {
              en: {
                title: null
              },
              de: {
                title: null
              }
            }
          }
        }
        
        this.question.content.options.push(option);
      },
      selectOptionIcon(data) {
        this.question.content.options[data.option].image = data.image;
      },
      addMultiQuestion() {
        let lastId = this.question.content.questions.length > 0 ? this.question.content.questions[this.question.content.questions.length - 1].id : 0;

        let question = {
          id: lastId + 1,
          image: null,
          translations: {
            en: {
              title: null
            },
            de: {
              title: null
            }
          },
          answers: []
        }

        this.question.content.questions.push(question);
      },
      addAnswer(questionIndex) {
        let lastId = this.question.content.questions[questionIndex].answers.length > 0 ? this.question.content.questions[questionIndex].answers[this.question.content.questions[questionIndex].answers.length - 1].id : 0;

        let answer = {
          id: lastId + 1,
          points: null,
          translations: {
            en: {
              title: null
            },
            de: {
              title: null
            }
          }
        }

        this.question.content.questions[questionIndex].answers.push(answer);
      },
      inputTypeAway() {
        this.inputTypeSelect = false;
      }
    },
    mounted() {
      this.getQuestionHandler();
    }
  }
</script>

<style scoped>
  .color-options {
    display: flex;
    gap: 12px;
  }
  .color-box {
    display: block;
    width: 26px;
    height: 26px;
    border-radius: 4px;
    cursor: pointer;
  }

  .color-box.active {
    outline: 3px solid #3C6E71;
  }

  .background-preview {
    max-width: 100%;
    max-height: 200px;
    margin: 20px 0;
    box-shadow: 0 0 10px rgb(0 0 0 / 30%);
  }

  .option,
  .question {
    background: rgb(224, 224, 224);
    padding: 20px 30px;
    border-radius: 10px;
  }

  .option .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .option .head .remove-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    outline: none;
    background: #3c6e71;
    color: #fafafb;
    border-radius: 8px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    font-size: 18px;
  }
</style>

<style>
  .slider-dropdown .select-dropdown,
  .icons-dropdown .select-dropdown {
    background-color: #656363 !important;
  }
</style>
