<template>
  <popup
      title="Inspect Financial Results Content"
      @close="$emit('close')"
  >

    <template v-slot:content>

      <span class="info-text">
        Name: {{name}}
      </span>

      <span class="info-text">
        ID: {{securityId}}
      </span>

      <span class="info-text">
        ISIN: {{isin}}
      </span>


      <!-- Manually modify description -->
      <span>
        <a :href="getSecurityUrl()"
           target="_blank"
        >
          Go to security on web app
        </a>

      </span>

      <div class="field">
        <label for="ai-content"> English: </label>
        <textarea type="text" id="ai-content" v-model="english"></textarea>
      </div>

      <div class="field">
        <label for="ai-content"> German: </label>
        <textarea type="text" id="ai-content-de" v-model="german"></textarea>
      </div>

      <!--       source url-->
      <div class="field">
        <label for="ai-content-source"> Url Source: </label>
        <a :href="source" target="_blank">Open Link</a>
        <textarea type="text" id="ai-content-source" v-model="source"></textarea>
      </div>


      <div class="field">
        <label class="custom-check green">
          <input type="checkbox" v-model="translate">
          <span class="checkmark"></span>
          Translate
        </label>
      </div>

      <!-- Paste context for chatGPT -->
      <div class="field">
        <label for="isin"><strong>Summarise with ChatGPT:</strong></label>
        <template v-if="!isUpdateLoading">
          <button class="set-field-btn"
                  @click="fetchChatGptSummary"
                  :disabled="isUpdateLoading"
                  v-if="chatContext">
            Summarise
          </button>
        </template>
        <template v-else>
          Loading...
        </template>
        <input type="text" id="isin" v-model="chatContext" placeholder="Context..." />
      </div>


      <!-- save button -->
      <button class="transition save-btn" @click="updateFinancialResultsHandler()" :disabled="isUpdateLoading">
        Save
      </button>
    </template>
  </popup>
</template>

<script>
import Popup from "@/components/shared/Popup.vue";
import securities from "@/mixins/securities.vue";
import users from "@/mixins/users.vue";

export default {
  name: 'InspectFinancialResult',
  mixins: [securities, users],
  props: {
    item: Object
  },
  components: {
    Popup,
  },
  data() {
    return {
      resultsId: this.item.results_id,
      securityId: this.item.security_id,
      name: this.item.name,
      isin: this.item.isin,
      url: this.item.url,
      english: this.item.en,
      german: this.item.de,
      source: this.item.source,
      translate: false,
      isUpdateLoading: false,
      isLoadingChatGptSummary: false,
      chatContext: null,
    }
  },

  methods: {
    updateFinancialResultsHandler(){
      this.isUpdateLoading = true;
      let dataToSend = {
        securityId: this.securityId,
        english: this.english !== this.item.en ? this.english : null,
        german: this.german !== this.item.de ? this.german : null,
        source: this.source !== this.item.source ? this.source : null,
        translate: this.translate
      }
      this.updateFinancialResults(dataToSend, this.resultsId)
          .then(() => {
            this.updateLoading = false;
            this.$emit('updated');
          })
          .catch(err => {
            this.updateLoading = false;
            if (err.code === 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
    },
    getSecurityUrl() {
      return "https://umushroom.com/en/equity/" + this.url;
    },

    fetchChatGptSummary(){
      this.isUpdateLoading = true;
      const prompt = "Give 5 very short paragraphs each stating a piece of information about " + this.name + "’s latest financial results/earnings highlights (like income, revenue, sales, earnings, profit, dividends, outlook etc.). If there is no content about financial results or earnings, don't answer anything. Special instructions: Introduce each paragraph with a very short title followed by the text, surround the title with a h4 html tag and the paragraphs with a p tag (without triple quotes formatting, just with the tags); always make sure you mention " + this.name + "'s name and which quarter of which year you are writing about :\n";


      let dataToSend = {
        context: prompt + this.chatContext,
      }

      this.getChatGptSummary(dataToSend)
          .then(response => {
            this.english = response.answer;
            this.isUpdateLoading = false;
          })
          .catch(err => {
            this.isUpdateLoading = false;
            if (err.code === 401) {
              this.logout();
            } else {
              this.error = err.msg;
              setTimeout(() => {
                this.error = null;
              }, 5000);
            }
          })
    },


  },

  watch: {

  },

  computed: {

  },

  mounted() {

  }
}
</script>

<style scoped>
.info-text {
  display: block;
  color: #171725;
  font-weight: 600;
  font-size: 16px;
  margin-top: 20px;
  text-transform: uppercase;
}

a {
  color: #3C6E71;
}

a:hover {
  text-decoration: none;
}

:deep(.set-field-btn) {
  margin-left: 3%;
  background-color: #FFF;
  color: #3C6E71;
  border: solid thin #3C6E71;
  outline: none;
  width: 30%;
  height: 30px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
}

:deep(.set-field-btn:not(:disabled):hover) {
  background-color: #3C6E71;
  color: #FFF;
  box-shadow: 0 0 10px rgba(0,0,0,0.3);
  border: none;
}

textarea#ai-content, textarea#ai-content-de {
  height: 300px;
}

textarea#ai-content-source {
  height: 80px;
}

</style>